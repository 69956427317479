@import '@ping/assets/scss/theme/variables';

.notfound-wrapper {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: $color-white;
  &:before {
    content: '';
    position: absolute;
    max-width: 800px;
    height: 100%;
    width: 100%;
    background-image: url(/img/404.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    background-size: contain;
    z-index: -1;
    @media only screen and (max-width: 576px) {
      width: 90%;
      top: -120px;
    }
  }

  .error-number {
    font-size: 128px;
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
    @media only screen and (max-width: 576px) {
      font-size: 80px;
      margin-bottom: 28px;
    }
  }

  .error-message {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 30px;
    @media only screen and (max-width: 576px) {
      font-size: 36px;
    }
  }

  .error-description {
    font-size: 20px;
    max-width: 500px;
    color: $color-gray-100;
    @media only screen and (max-width: 576px) {
      padding: 0 20px;
      margin-top: 70px;
      font-size: 16px;
    }
  }

  .goback-button {
    padding: 8px 50px;
    width: 300px;
    max-width: 100%;
    margin-top: 56px;
    @media only screen and (max-width: 576px) {
      max-width: 190px;
    }
  }
}
